import React, { useState } from 'react';
import { BuilderComponent } from '@builder.io/react';
import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import '../builder-settings';

const LandingPage = () => {
    const [useNav, setUseNav] = useState(true);
    return (
        <>
            <HtmlHead />
            {useNav && <Navbar />}
            <BuilderComponent model="page" contentLoaded={content => setUseNav(content.useNav)} />
            {useNav && <Footer />}
        </>
    );
};

export default LandingPage;
